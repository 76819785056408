module.exports = {
    logon: '登入',
    register: '注册',
    appDown: 'APP下載',
    appDownPc: '軟件下載',
    footer: ' 版權所有',
    vpn: '節點加速',
    vpn_mo: '節點',
    ssl: '獲取SSL節點',
    ssl_mo: 'SSL節點',
    ssl_copy: '複製成功',
    ssl_copy_fail: '複製失敗',
    home: '首頁',
    menuabout: '關於我們',
    banner: [{
        title: '世界領先的數字資產交易平臺',
        info: '產業服務一站式平臺，覆蓋全球160多個城市、擁有8900萬+用戶共享金融、公開透明、安全投資快速交易'
    }],

    whybox: {
        bottomTitle: '數分鐘內即可投資比特幣，以太坊等其他數字資產',
        bottomInfo: '入門非常容易，快速和安全。',
        title: '為什麽要選擇',
        info: '蓋全球160多個城市、大數據分析約8900萬+真實用戶，參與體驗和功能使用',
        swiper: [{
            title: '通過公開透明建立聯系',
            subtitle: '凡事以用戶的利益考量為先',
            info: '可靠的定價機制反映最準確的標記價格和指數價格演算法。交易者可隨心使用大量實時數據。我們友善、經驗豐富的客服團隊也提供7*24小時在線服務，無時不刻回應您的建議及疑問。'
        }, {
            title: '堅如磐石的安全性',
            subtitle: '保障您的資金安全是我們的首要任務是以用戶的利益考量為先',
            info: '100％的用戶資金存儲在我們的錢包中。作為全球合約市場深度領跑者，您可以在我們的平臺安心地交易。嚴謹的提款流程以及通過離線簽名進行存/取款的制度可最大程度確保資金安全。'
        }],
        openDeal: '開啟交易'
    },
    processBox: {
        title1: '註冊',
        info1: '輸入手機號或電子郵件進行註冊',
        title2: '完成驗證並存入資金',
        info2: '通過身份認證輕松獲得獎勵',
        title3: '開始進行數字資產投資',
        info3: '登錄以開始充值或交易'
    },
    transaction: {
        title: '輕松完成任意一筆交易',
        info: '例如：交易員期望當交易被收購時，BTC將更有利可圖。交易者買入。如果實際要約價格上漲，則交易者將獲得利潤，但是如果實際價格低於交易者，則將損失。',
        doTransaction: '立即交易'
    },
    ratebox: {
        title1: '2018年12月上線至今',
        title2: '榮獲全球300+主流媒體的認可',
        info1: '已有',
        info2: '用戶參與評價'
    },
    screenshot: {
        info1: '區塊鏈數字資產交易平臺',
        title1: '國際權威的',
        title2: '數字資產交易平臺',
    },
    problem: {
        title: '常見問題',
        info: '我們很樂意回答您的問題，以便您能立即開始創建數字資產投資策略。',
        more: '更多問題',
        quelist: [{
                que: '如何找回密碼？',
                ans: '如果您忘記了密碼，可以通過以下方式找回密碼:1.重置電子郵箱密碼；2.重置手機號密碼：（點擊頁面右下方的“忘記密碼”，輸入您的電子郵箱/手機號以接收驗證碼。輸入您的新密碼，確認您的新密碼，並輸入您郵箱或手機裏收到的驗證碼，點擊“下一步”。您的新密碼已設置成功',
            },
            {
                que: '如何綁定/修改手機號碼？',
                ans: '如果您忘記了密碼，可以通過以下方式找回密碼:1.重置電子郵箱密碼；2.重置手機號密碼：（點擊頁面右下方的“忘記密碼”，輸入您的電子郵箱/手機號以接收驗證碼。輸入您的新密碼，確認您的新密碼，並輸入您郵箱或手機裏收到的驗證碼，點擊“下一步”。您的新密碼已設置成功'
            }
        ],
    },
    // mapbox: {
    //     addres1: '地址：薩克拉門托，加利福尼亞州，222 Woodmere Road，Suite 250爾瑟姆，加利福尼亞州95630',
    //     title: '聯系信息',
    //     addres2: '美國薩克拉門托，加利福尼亞州 95630',
    //     msg: 'info@coinfoex.com'
    // },
	// DGX
    // mapbox: {
    //     addres1: '地址：新加坡6 Eu Tong Sen街道，#07-10，059817',
    //     title: '聯系信息',
    //     addres2: '新加坡6 Eu Tong Sen街道，#07-10，059817',
    //     msg: 'info@dgxpro.com'
    // },
	//AUSFOREX
	mapbox: {
        addres1: '地址：Suite 14 Level 11, 65 York Street, SYDNEY NSW 2000',
        title: '聯系信息',
        addres2: 'Suite 14 Level 11, 65 York Street, SYDNEY NSW 2000',
        msg: 'info@coinfoex.com'
    },


    //關於我們
    about: {
        homeabout: '首頁 / 關於我們',
        bannertitle: '創新，重塑，革命：',
        bannerinfo: '致力於為投資者提供專業，安全的交易服務，以便為全世界每一位用戶打開金融市場的大門。',
        aboutus: '關於我們',
        info1: `面向專業用戶的全球領先數字資產金融服務商，致力於為全球用戶提供安全，可信賴的數字資產交易、外匯交易及資產管理服務。本著集團本身各方面的優勢，依托多年的積累與穩健發展，現已成長為具有全球競爭力與影響力的企業。在技​​術平台、產品支線、安全風控體系、運營及客戶服務體係等方面，均處於全球領先地位。
        <p> 定位：致力為全球用戶提供專業、安全、透明的數字資產金融服務。 </p>
        <p> 規模：覆蓋全球全球160多個城市，擁有8900萬+用戶，累計交易金額超過0.8萬億美元。
            安全：安全運營，數字資產存儲多重簽名數字貨幣錢包。
            穩定：全球頂尖技術團隊，分佈式並行技術，高速撮合引擎。
            專業：超強金融風控團隊，支持API交易。 </p>
		`,
        info2: `【產品特點】
        <p>1. 支持多幣種的交易：LTC/BTC、ETH/BTC、BCH/BTC、ETC/BTC、BTC/USDT、LTC/USDT、ETH/ USDT、BCH/ USDT、ETC/ USDT、EUR/ USD、GBP/USD、AUD/USD、XAU/USD等。 </p>
        <p>2. 實時價格</p>
        <p>3. 專業K線圖、實時指標</p>
        <p>4. 靈活交易方式：現價交易、市價交易、止盈止損交易</p>
        <p>5. 提幣充幣快速到賬</p>
        <p>6. 7*24小時多語言服務</p>
        <p>7. 便捷的資產查詢</p>
		`,
        title2: '為所有人打開全球市場，方便大家以簡單而透明的方式進行交易和投資。',
        title3: '我們的價值觀',
        itemsub1: '簡單',
        iteminfo1: '我們的主要目標之一是清除障礙，讓普通用戶能方便地進行網上交易和投資。無論人們從何時起加入，我們都努力讓他們從一開始就感到自己是平臺的一員。此外我們還認識到，許多人通過多個網上平臺來管理其資本。因此我們不斷擴展產品範圍，以期最終實現讓用戶通過一個平臺即可滿足所有的財務需求。',
        itemsub2: '創新',
        iteminfo2: '我們從金融科技革命的最早期開始便參與其中，並為此深感自豪。我們推出創新性的網上交易和投資方式，發明最先進的金融產品，所有這些讓我們成為並將一直擔當金融科技領域的創新者和顛覆者。',
        itemsub3: '樂趣',
        iteminfo3: '公開透明和絕對公平是我們用戶體驗中不可或缺的一部分。從與包括我們的負責人在內的其他用戶進行交流，到不收取任何隱性費用和確保我們的價格清晰透明，我們采取各種措施盡力確保用戶掌握全部所需信息，以便在平臺上進行金融操作。',
        itemsub4: '優質',
        iteminfo4: '每個決定背後的周密思考以及對完美的不懈追求，引領平臺為所有用戶帶來最佳體驗。從看到我們標誌的那一刻開始，我們希望每個人都能隨時感受到我們對所有細小環節的一絲不茍。',
        title4: '我們的願景',
        info4: '我們時刻傾聽、關心和思進。我們希望可以融合加密貨幣和傳統金融業的優勢不斷地創新。通過卓越的平臺能力和專業的設計理念為我們的用戶帶來行業內最安全、高效、公平和透明的交易體驗。',
    },
    appstroe: {
        text1: '預覽',
        text2: '當前Web版App Store 免費下載App。',
        text3: '快速精準全面的貨幣交易平臺',
        text4: '取得',
        text5: 'App內購買',
        text6: '5.2萬個評分',
        text7: '年齡',
        text8: '歲',
        text9: '類別',
        text10: '財務',
        text11: '面向專業用戶的全球領先數字資產金融服務商，致力於為全球用戶提供安全，可信賴的數字資產交易、外匯交易及資產管理服務。本著集團本身各方面的優勢，依托多年的積累與穩健發展，現已成長為具有全球競爭力與影響力的企業。在技術平臺、產品支線、安全風控體系、運營及客戶服務體系等方面，均處於全球領先地位。 定位：致力為全球用戶提供專業、安全、透明的數字資產金融服務。 規模：覆蓋全球全球160多個城市，擁有8900萬+用戶，累計交易金額超過0.8萬億美元。 安全：安全運營，數字資產存儲多重簽名數字貨幣錢包。 穩定：全球頂尖技術團隊，分布式並行技術，高速撮合引擎。 專業：超強金融風控團隊，支持API交易。 【產品特點】 1.支持多幣種的交易：LTC/BTC、ETH/BTC、BCH/BTC、ETC/BTC、BTC/USDT、LTC/USDT、ETH/ USDT、BCH/ USDT、ETC/ USDT、EUR/USD、GBP/USD、AUD/USD、XAU/USD等。 2.實時價格 3.專業K線圖、實時指標 4.靈活交易方式：現價交易、市價交易、止盈止損交易 5.提幣充幣快速到賬 6.7*24小時多語言服務 7.便捷的資產查詢 ',
        text12: '更多',
        text13: '開發者',
        text14: '評分及評論',
        text15: '滿分5分',
        text16: '52,569個評分',

        text21: '信息',
        text22: '更新日期',
        text23: '2021年3月30日',
        text24: '大小',
        text25: '安裝次數',
        text26: '當前版本',
        text27: '內容分級',
        text28: '開發者網站',
        text29: '收起',

    },
    google: {
        text1: '財務',
        text2: '青少年',
        text3: '安裝',
        text4: '添加到心願單',
        text5: '面向專業用戶的全球領先數字資產金融服務商，致力於為全球用戶提供安全，可信賴的數字資產交易、外匯交易及資產管理服務。本著集團本身各方面的優勢，依托多年的積累與穩健發展，現已成長為具有全球競爭力與影響力的企業。在技術平臺、產品支線、安全風控體系、運營及客戶服務體系等方面，均處於全球領先地位。 定位：致力為全球用戶提供專業、安全、透明的數字資產金融服務。 規模：覆蓋全球全球160多個城市，擁有8900萬+用戶，累計交易金額超過0.8萬億美元。 安全：安全運營，數字資產存儲多重簽名數字貨幣錢包。 穩定：全球頂尖技術團隊，分布式並行技術，高速撮合引擎。 專業：超強金融風控團隊，支持API交易。 【產品特點】 1.支持多幣種的交易：LTC/BTC、ETH/BTC、BCH/BTC、ETC/BTC、BTC/USDT、LTC/USDT、ETH/ USDT、BCH/ USDT、ETC/ USDT、EUR/USD、GBP/USD、AUD/USD、XAU/USD等。 2.實時價格 3.專業K線圖、實時指標 4.靈活交易方式：現價交易、市價交易、止盈止損交易 5.提幣充幣快速到賬 6.7*24小時多語言服務 7.便捷的資產查詢',
        text6: '收起',
        text7: '展開',
        text8: '其他信息',
        text9: '提供者',
        text10: '系統要求',
        text11: '因設備而異',
        text12: '訪問網站',
        text13: '歲',
        text22: '更新日期',
        text23: '2021年3月30日',
        text24: '大小',
        text25: '安裝次數',
        text26: '當前版本',
        text27: '內容分級',
        text28: '開發者',
    }
}