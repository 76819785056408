import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2';
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/css/swiper.css';
import VueI18n from 'vue-i18n';

Vue.use(VueClipboard)
Vue.use(VueI18n)
let config = process.env
let tw = require('./lang/tw')
let msg: any = {}
for (let i = 0; i < config.langs.length; i++) {
  msg[config.langs[i].name] = require('./lang/' + config.langs[i].name)
}
msg['tw'] = tw
let currentLang: number = parseInt(config.currentLang)
if (localStorage.getItem('currentLang')) {
  currentLang = parseInt(localStorage.getItem('currentLang'))
}
let name = config.langs[currentLang].name
config.currentLang = currentLang
if (localStorage.getItem('applang')) {
  name = localStorage.getItem('applang')
}
document.title = config.docTitle
const i18n = new VueI18n({
  locale: name, // 语言标识 
  messages: msg
});
(function () {
  if (config.ico) {
    let link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = config.ico;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
})();
Vue.prototype.config = config
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper)
Vue.prototype._i18n = i18n
Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
