import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import index from '../views/mo/moindex.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/apple',
    name: 'apple',
    component: () => import('../views/apple/index.vue'),
  },
  {
    path: '/google',
    name: 'google',
    component: () => import('../views/google/index.vue'),
  },
  {
    path: '/mo',
    name: 'mo',
    component: () => import('../views/mo/moindex.vue'),
    children: [
      {
        path: '/',
        name: 'mohome',
        component: () => import('../views/apple/index.vue'),
      },
      {
        path: '/about',
        name: 'moabout',
        component: () => import('../views/mo/about.vue'),
      },
    ]
  },
  {
    path: '/',
    name: 'google1',
    component: () => import('../views/google/index.vue'),
  },
  // {
  //   path: '/',
  //   name: 'pc',
  //   component: () => import('../views/pc/pcindex.vue'),
  //   children: [
  //     {
  //       path: '/',
  //       name: 'pchome',
  //       component: () => import('../views/pc/Home.vue'),
  //     },
  //     {
  //       path: '/pcabout',
  //       name: 'pcabout',
  //       component: () => import('../views/pc/about.vue'),
  //     },
  //   ]
  // },

];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});
function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array(
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod"
  );
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

function appSource() {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOS) {
    return "ios";
  } else {
    return "andriod";
  }
}

router.beforeEach((to, from, next) => {
  let hash = window.location.hash;
  let strArr = hash.split("?lang=");
  let url = "";
  if(strArr.length  > 1) {
    let e = strArr[1];
    if(['en', 'zh'].indexOf(e) > -1) {
      url = '?lang=' + e;
    } else {
      url = '?lang=en';
    }
  }
  if (to.path == '/') {
    if (appSource() == 'ios'){
      next('/apple' + url)
    }else{
      next('/google' + url)
    }
    // if (IsPC()) {
    //   next()
    // } else {
    //   next('/mo' + url)
    // }
  } else {
    next();
  }

})


export default router;
