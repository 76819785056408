module.exports = {
    logon: 'Login',
    register: 'Register',
    appDown: 'Download',
    appDownPc: 'Download',
    footer: ' copyright',
    vpn: 'Acceleration',
    vpn_mo: 'ACC',
    ssl: 'Get SSL node',
    ssl_mo: 'SSL node',
    ssl_copy: 'Copy successfully',
    ssl_copy_fail: 'Copy failed',
    home: 'Home',
    menuabout: 'About',
    banner: [{
        title: "The world's leading digital asset trading platform",
        info: "The one-stop platform of industrial services covers more than 160 cities in the world, with 89 million + users sharing finance, openness and transparency, safe investment and rapid trading"
    }],
    whybox: {
        bottomTitle: 'Invest in Bitcoin, Ethereum and other digital assets within minutes',
        bottomInfo: 'Getting started is very easy, fast and safe。',
        title: 'Why choose',
        info: 'Covering more than 160 cities around the world, big data analysis about 89 million + real users participate in experience and function use',
        swiper: [{
                title: 'Connect through openness and transparency',
                subtitle: 'Everything is based on the interests of users',
                info: 'Reliable pricing mechanism reflects the most accurate mark price and index price algorithm. Traders can use a lot of real-time data at will. Our friendly and experienced customer service team also provides 7 * 24-hour online service to respond to your suggestions and questions all the time.'
            },
            {
                title: 'Connect through openness and transparency',
                subtitle: 'Everything is based on the interests of users',
                info: 'Reliable pricing mechanism reflects the most accurate mark price and index price algorithm. Traders can use a lot of real-time data at will. Our friendly and experienced customer service team also provides 7 * 24-hour online service to respond to your suggestions and questions all the time.'
            }
        ],
        openDeal: 'Open a deal'
    },
    processBox: {
        title1: 'register',
        info1: 'Enter your mobile phone number or email to register',
        title2: 'Complete verification and deposit funds',
        info2: 'Get rewards easily through identity authentication',
        title3: 'Start digital asset investment',
        info3: 'Log in to start recharging or trading'
    },
    transaction: {
        title: 'Easily complete any transaction',
        info: 'For example, traders expect BTC to be more profitable when the deal is acquired. Traders buy. If the actual offer price rises, the trader will gain profit, but if the actual price is lower than the trader, he will lose.',
        doTransaction: 'Deal now'
    },
    ratebox: {
        title1: 'Online since December 2018',
        title2: 'Recognized by 300 + global mainstream media',
        info1: 'already existing',
        info2: 'User participation evaluation'
    },
    screenshot: {
        info1: 'Blockchain digital asset trading platform',
        title1: 'International authority',
        title2: 'Digital asset trading platform',
    },
    problem: {
        title: 'common problem',
        info: 'We are happy to answer your questions so that you can start to create a digital asset investment strategy immediately',
        more: 'More questions',
        quelist: [{
                que: 'How to retrieve the password?',
                ans: 'If you forget your password, you can retrieve it in the following ways: 1. Reset your email password; 2. Reset your mobile phone number password: (click "forget password" at the bottom right of the page to enter your email / mobile phone number to receive the verification code. Enter your new password, confirm your new password, and enter the verification code received in your email or mobile phone, and click "next". Your new password has been set successfully',
            },
            {
                que: 'How to bind / modify mobile phone number?',
                ans: 'If you forget your password, you can retrieve it in the following ways: 1. Reset your email password; 2. Reset your mobile phone number password: (click "forget password" at the bottom right of the page to enter your email / mobile phone number to receive the verification code. Enter your new password, confirm your new password, and enter the verification code received in your email or mobile phone, and click "next". Your new password has been set successfully'
            }
        ],
    },
    // mapbox: {
    //     addres1: 'Address: Sacramento, 222 woodmer Road, suite 250 elsham, CA 95630',
    //     title: 'Contact information',
    //     addres2: 'Sacramento, CA 95630, USA',
    //     msg: 'info@coinfoex.com'
    // },
    // //gex
    // mapbox: {
        // addres1: 'Address: 6 EU Tong Sen street, #07-10, 059817, Singapore',
        // title: 'Contact information',
        // addres2: '6 EU Tong Sen street, #07-10, 059817, Singapore',
        // msg: 'info@coinfoex.com'
    // },
	mapbox: {
	    addres1: 'Suite 14 Level 11, 65 York Street, SYDNEY NSW 2000',
	    title: 'Contact information',
	    addres2: 'Suite 14 Level 11, 65 York Street, SYDNEY NSW 2000',
	    msg: 'info@coinfoex.com'
	},


    //关于我们
    about: {
        homeabout: 'Home / About',
        bannertitle: 'Innovation, remolding, Revolution:',
        bannerinfo: 'Committed to providing investors with professional, safe trading services, in order to open the door of the financial market for every user in the world.',
        aboutus: 'About us',
        info1: `The world's leading digital asset financial service provider for professional users is committed to providing safe and reliable digital asset transactions, foreign exchange transactions and asset management services for global users. Based on the advantages of the group itself in all aspects, relying on years of accumulation and steady development, it has grown into an enterprise with global competitiveness and influence. In terms of technology platform, product branch line, safety risk control system, operation and customer service system, it is in a leading position in the world.
        <p> Positioning: Committed to providing professional, safe and transparent digital asset financial services to users around the world. </p>
        <p> Scale: Covering more than 160 cities around the world, with 89 million+ users, and a cumulative transaction amount of more than 0.8 trillion US dollars.
            Security: Safe operation, multi-signature digital currency wallet for digital asset storage.
            Stability: The world's top technical team, distributed parallel technology, high-speed matching engine.
            Professional: Super-strong financial risk control team, support API transactions. </p>
		 `,
        info2: `[Product Features]
        <p>1. Support multi-currency transactions: LTC/BTC, ETH/BTC, BCH/BTC, ETC/BTC, BTC/USDT, LTC/USDT, ETH/ USDT, BCH/ USDT, ETC/ USDT, EUR/ USD, GBP/USD, AUD/USD, XAU/USD, etc. </p>
        <p>2. Real-time price</p>
        <p>3. Professional K-line chart, real-time indicators</p>
        <p>4. Flexible trading methods: current price trading, market price trading, stop-profit and stop-loss trading</p>
        <p>5. Withdraw coins and deposit coins quickly to the account</p>
        <p>6. 7*24 hours multilingual service</p>
        <p>7. Convenient asset query</p>
		`,
        title2: 'Open up the global market for all to trade and invest in a simple and transparent way.',
        title3: 'Our values',
        itemsub1: 'simple',
        iteminfo1: 'One of our main goals is to remove barriers and make it easy for ordinary users to trade and invest online. Whenever people join, we try to make them feel part of the platform from the beginning. We also recognize that many people manage their capital through multiple online platforms. Therefore, we continue to expand the range of products, in order to ultimately enable users to meet all financial needs through one platform.',
        itemsub2: 'innovate',
        iteminfo2: 'We have been involved in the financial technology revolution since its earliest days, and we are very proud of it. We have launched innovative online trading and investment methods, invented the most advanced financial products, all of which make us and will always act as innovators and subverters in the field of financial technology.',
        itemsub3: 'pleasure',
        iteminfo3: 'Openness, transparency and absolute fairness are an integral part of our user experience. From communicating with other users, including our responsible person, to not charging any hidden fees and ensuring that our prices are clear and transparent, we take various measures to ensure that users have all the information they need for financial operations on the platform.',
        itemsub4: 'high quality',
        iteminfo4: 'The careful thinking behind each decision and the unremitting pursuit of perfection lead the platform to bring the best experience to all users. From the moment we see our logo, we hope everyone can feel our meticulousness to all the small links at any time',
        title4: 'Our vision',
        info4: 'We always listen, care and think. We hope to integrate the advantages of cryptocurrency and traditional financial industry, and constantly innovate. Through excellent platform capability and professional design concept, we bring our users the most secure, efficient, fair and transparent trading experience in the industry.',
    },
    appstroe: {
        text1: 'preview',
        text2: 'The current web app store downloads apps for free.',
        text3: 'Fast, accurate and comprehensive currency trading platform',
        text4: 'GET',
        text5: 'Purchase in app',
        text6: '52000 scores',
        text7: 'Age',
        text8: 'year',
        text9: 'category',
        text10: 'Finance',
        text11: 'Forex and stock trading anytime, anywhere!',
        // text11: "The world's leading digital asset financial service provider for professional users is committed to providing safe and reliable digital asset trading, foreign exchange trading and asset management services for global users. Based on the advantages of the group itself, relying on years of accumulation and steady development, the group has grown into an enterprise with global competitiveness and influence. In terms of technology platform, product branch line, safety risk control system, operation and customer service system, it is in a leading position in the world. Positioning: to provide professional, safe and transparent digital asset financial services for global users. Scale: it covers more than 160 cities in the world, has 89 million + users, and the accumulated transaction amount exceeds US $0.8 trillion. Security: secure operation, digital asset storage, multi signature digital currency wallet. Stable: global top technology team, distributed parallel technology, high speed matchmaking engine. Specialty: super financial risk control team, supporting API transactions. [product features] 1. Support multi currency transactions: LTC / BTC, ETH / BTC, BCH / BTC, etc / BTC, BTC / usdt, LTC / usdt, ETH / usdt, BCH / usdt, etc / usdt, EUR / USD, GBP / USD, aud / USD, XAU / USD, etc. 2. Real time price 3. Professional K-line chart, real-time indicators 4. Flexible trading methods: current price trading, market price trading, stop loss trading 5. Quick cash collection and charging 6. 7 * 24-hour multilingual service 7. Convenient asset query",
        text12: 'more',
        text13: 'developer',
        text14: 'Ratings and comments',
        text15: 'The full score is 5',
        text16: '52569 scores',

        text21: 'information',
        text22: 'Update date',
        text23: 'March 30, 2021',
        text24: 'size',
        text25: 'Installation times',
        text26: 'current version',
        text27: 'Content rating',
        text28: 'Developer website',
        text29: 'Put it away',

    },
    google: {
        text1: 'Finance',
        text2: 'teenagers',
        text3: 'install',
        text4: 'Add to wish list',
        text5: 'Forex and stock trading anytime, anywhere!',
        // text5: "The world's leading digital asset financial service provider for professional users is committed to providing safe and reliable digital asset trading, foreign exchange trading and asset management services for global users. Based on the advantages of the group itself, relying on years of accumulation and steady development, the group has grown into an enterprise with global competitiveness and influence. In terms of technology platform, product branch line, safety risk control system, operation and customer service system, it is in a leading position in the world. Positioning: to provide professional, safe and transparent digital asset financial services for global users. Scale: it covers more than 160 cities in the world, has 89 million + users, and the accumulated transaction amount exceeds US $0.8 trillion. Security: secure operation, digital asset storage, multi signature digital currency wallet. Stable: global top technology team, distributed parallel technology, high speed matchmaking engine. Specialty: super financial risk control team, supporting API transactions. [product features] 1. Support multi currency transactions: LTC / BTC, ETH / BTC, BCH / BTC, etc / BTC, BTC / usdt, LTC / usdt, ETH / usdt, BCH / usdt, etc / usdt, EUR / USD, GBP / USD, aud / USD, XAU / USD, etc. 2. Real time price 3. Professional K-line chart, real-time indicators 4. Flexible trading methods: current price trading, market price trading, stop loss trading 5. Quick cash collection and charging 6. 7 * 24-hour multilingual service 7. Convenient asset query",
        text6: 'Put it away',
        text7: 'open',
        text8: 'Other information',
        text9: 'Provider',
        text10: 'system requirements',
        text11: 'Device specific',
        text12: 'Visit the website',
        text13: 'year',
        text22: 'Update date',
        text23: 'March 30, 2021',
        text24: 'size',
        text25: 'Installation times',
        text26: 'current version',
        text27: 'Content rating',
        text28: 'developer',
    }
}