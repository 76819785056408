module.exports = {
    logon: '登录',
    register: '注册',
    appDown: 'APP下载',
    appDownPc: '软件下载',
    footer: ' 版权所有',
    vpn: '节点加速',
    vpn_mo: '节点',
    ssl: '获取SSL节点',
    ssl_mo: 'SSL节点',
    ssl_copy: '获取成功，请粘贴至应用程序使用',
    ssl_copy_fail: '复制失败',
    home: '首页',
    menuabout: '关于我们',
    banner: [{
        title: '世界领先的数字资产交易平台',
        info: '产业服务一站式平台，覆盖全球160多个城市、拥有8900万+用户共享金融、公开透明、安全投资快速交易'
    }],

    whybox: {
        bottomTitle: '数分钟内即可投资比特币，以太坊等其他数字资产',
        bottomInfo: '入门非常容易，快速和安全。',
        title: '为什么要选择',
        info: '覆盖全球160多个城市、大数据分析约8900万+真实用户，参与体验和功能使用',
        swiper: [{
            title: '通过公开透明建立联系',
            subtitle: '凡事以用户的利益考量为先',
            info: '可靠的定价机制反映最准确的标记价格和指数价格演算法。交易者可随心使用大量实时数据。我们友善、经验丰富的客服团队也提供7*24小时在线服务，无时不刻回应您的建议及疑问。'
        }, {
            title: '坚如磐石的安全性',
            subtitle: '保障您的资金安全是我们的首要任务是以用户的利益考量为先',
            info: '100％的用户资金存储在我们的钱包中。作为全球合约市场深度领跑者，您可以在我们的平台安心地交易。严谨的提款流程以及通过离线签名进行存/取款的制度可最大程度确保资金安全。'
        }],
        openDeal: '开启交易'
    },
    processBox: {
        title1: '注册',
        info1: '输入手机号或电子邮件进行注册',
        title2: '完成验证并存入资金',
        info2: '通过身份认证轻松获得奖励',
        title3: '开始进行数字资产投资',
        info3: '登录以开始充值或交易'
    },
    transaction: {
        title: '轻松完成任意一笔交易',
        info: '例如：交易员期望当交易被收购时，BTC将更有利可图。交易者买入。如果实际要约价格上涨，则交易者将获得利润，但是如果实际价格低于交易者，则将损失。',
        doTransaction: '立即交易'
    },
    ratebox: {
        title1: '2018年12月上线至今',
        title2: '荣获全球300+主流媒体的认可',
        info1: '已有',
        info2: '用户参与评价'
    },
    screenshot: {
        info1: '区块链数字资产交易平台',
        title1: '国际权威的',
        title2: '数字资产交易平台',
    },
    problem: {
        title: '常见问题',
        info: '我们很乐意回答您的问题，以便您能立即开始创建数字资产投资策略。',
        more: '更多问题',
        quelist: [{
                que: '如何找回密码？',
                ans: '如果您忘记了密码，可以通过以下方式找回密码:1.重置电子邮箱密码；2.重置手机号密码：（点击页面右下方的“忘记密码”，输入您的电子邮箱/手机号以接收验证码。输入您的新密码，确认您的新密码，并输入您邮箱或手机里收到的验证码，点击“下一步”。您的新密码已设置成功',
            },
            {
                que: '如何绑定/修改手机号码？',
                ans: '如果您忘记了密码，可以通过以下方式找回密码:1.重置电子邮箱密码；2.重置手机号密码：（点击页面右下方的“忘记密码”，输入您的电子邮箱/手机号以接收验证码。输入您的新密码，确认您的新密码，并输入您邮箱或手机里收到的验证码，点击“下一步”。您的新密码已设置成功'
            }
        ],
    },
    //all
    // mapbox: {
    //     addres1: '地址：萨克拉门托，加利福尼亚州，222 Woodmere Road，Suite 250尔瑟姆，加利福尼亚州95630',
    //     title: '联系信息',
    //     addres2: '美国萨克拉门托，加利福尼亚州 95630',
    //     msg: 'info@coinfoex.com'
    // },
    // //gex
    // mapbox: {
    //     addres1: '地址：新加坡 6 Eu Tong Sen街道, #07-10, 059817',
    //     title: '联系信息',
    //     addres2: '新加坡，6 Eu Tong Sen街道, #07-10, 059817',
    //     msg: 'info@coinfoex.com'
    // },
	//AUSFOREX
	mapbox: {
	    addres1: '地址：Suite 14 Level 11, 65 York Street, SYDNEY NSW 2000',
	    title: '联系信息',
	    addres2: 'Suite 14 Level 11, 65 York Street, SYDNEY NSW 2000',
	    msg: 'info@coinfoex.com'
	},

    //关于我们
    about: {
        homeabout: '首页 / 关于我们',
        bannertitle: '创新，重塑，革命：',
        bannerinfo: '致力于为投资者提供专业，安全的交易服务，以便为全世界每一位用户打开金融市场的大门。',
        aboutus: '关于我们',
        info1: `
        面向专业用户的全球领先数字资产金融服务商，致力于为全球用户提供安全，可信赖的数字资产交易、外汇交易及资产管理服务。本着集团本身各方面的优势，依托多年的积累与稳健发展，现已成长为具有全球竞争力与影响力的企业。在技术平台、产品支线、安全风控体系、运营及客户服务体系等方面，均处于全球领先地位。
        <p> 定位：致力为全球用户提供专业、安全、透明的数字资产金融服务。</p>
        <p> 规模：覆盖全球全球160多个城市，拥有8900万+用户，累计交易金额超过0.8万亿美元。</p>
        <p> 安全：安全运营，数字资产存储多重签名数字货币钱包。</p>
        <p> 稳定：全球顶尖技术团队，分布式并行技术，高速撮合引擎。</p>
        <p> 专业：超强金融风控团队，支持API交易。</p>
        `,
        info2: `
		【产品特点】
        <p> 1. 支持多币种的交易：LTC/BTC、ETH/BTC、BCH/BTC、ETC/BTC、BTC/USDT、LTC/USDT、ETH/ USDT、BCH/ USDT、ETC/ USDT、EUR/USD、GBP/USD、AUD/USD、XAU/USD等。</p>
        <p> 2. 实时价格 </p>
        <p> 3. 专业K线图、实时指标 </p>
        <p> 4. 灵活交易方式：现价交易、市价交易、止盈止损交易 </p>
        <p> 5. 提币充币快速到账 </p>
        <p> 6. 7*24小时多语言服务 </p>
        <p> 7. 便捷的资产查询 </p>
		`,
        title2: '为所有人打开全球市场，方便大家以简单而透明的方式进行交易和投资。',
        title3: '我们的价值观',
        itemsub1: '简单',
        iteminfo1: '我们的主要目标之一是清除障碍，让普通用户能方便地进行网上交易和投资。无论人们从何时起加入，我们都努力让他们从一开始就感到自己是平台的一员。此外我们还认识到，许多人通过多个网上平台来管理其资本。因此我们不断扩展产品范围，以期最终实现让用户通过一个平台即可满足所有的财务需求。',
        itemsub2: '创新',
        iteminfo2: '我们从金融科技革命的最早期开始便参与其中，并为此深感自豪。我们推出创新性的网上交易和投资方式，发明最先进的金融产品，所有这些让我们成为并将一直担当金融科技领域的创新者和颠覆者。',
        itemsub3: '乐趣',
        iteminfo3: '公开透明和绝对公平是我们用户体验中不可或缺的一部分。从与包括我们的负责人在内的其他用户进行交流，到不收取任何隐性费用和确保我们的价格清晰透明，我们采取各种措施尽力确保用户掌握全部所需信息，以便在平台上进行金融操作。',
        itemsub4: '优质',
        iteminfo4: '每个决定背后的周密思考以及对完美的不懈追求，引领平台为所有用户带来最佳体验。从看到我们标志的那一刻开始，我们希望每个人都能随时感受到我们对所有细小环节的一丝不苟。',
        title4: '我们的愿景',
        info4: '我们时刻倾听、关心和思进。我们希望可以融合加密货币和传统金融业的优势不断地创新。通过卓越的平台能力和专业的设计理念为我们的用户带来行业内最安全、高效、公平和透明的交易体验。',
    },
    appstroe: {
        text1: '预览',
        text2: '当前Web版App Store 免费下载App。',
        text3: '快速精准全面的货币交易平台',
        text4: '取得',
        text5: 'App内购买',
        text6: '5.2万个评分',
        text7: '年龄',
        text8: '岁',
        text9: '类别',
        text10: '财务',
        text11: '面向专业用户的全球领先数字资产金融服务商，致力于为全球用户提供安全，可信赖的数字资产交易、外汇交易及资产管理服务。本着集团本身各方面的优势，依托多年的积累与稳健发展，现已成长为具有全球竞争力与影响力的企业。在技术平台、产品支线、安全风控体系、运营及客户服务体系等方面，均处于全球领先地位。 定位：致力为全球用户提供专业、安全、透明的数字资产金融服务。 规模：覆盖全球全球160多个城市，拥有8900万+用户，累计交易金额超过0.8万亿美元。 安全：安全运营，数字资产存储多重签名数字货币钱包。 稳定：全球顶尖技术团队，分布式并行技术，高速撮合引擎。 专业：超强金融风控团队，支持API交易。 【产品特点】 1.支持多币种的交易：LTC/BTC、ETH/BTC、BCH/BTC、ETC/BTC、BTC/USDT、LTC/USDT、ETH/ USDT、BCH/ USDT、ETC/ USDT、EUR/USD、GBP/USD、AUD/USD、XAU/USD等。 2.实时价格 3.专业K线图、实时指标 4.灵活交易方式：现价交易、市价交易、止盈止损交易 5.提币充币快速到账 6.7*24小时多语言服务 7.便捷的资产查询 ',
        text12: '更多',
        text13: '开发者',
        text14: '评分及评论',
        text15: '满分5分',
        text16: '52,569个评分',

        text21: '信息',
        text22: '更新日期',
        text23: '2021年3月30日',
        text24: '大小',
        text25: '安装次数',
        text26: '当前版本',
        text27: '内容分级',
        text28: '开发者网站',
        text29: '收起',

    },
    google: {
        text1: '财务',
        text2: '青少年',
        text3: '安装',
        text4: '添加到心愿单',
        text5: '面向专业用户的全球领先数字资产金融服务商，致力于为全球用户提供安全，可信赖的数字资产交易、外汇交易及资产管理服务。本着集团本身各方面的优势，依托多年的积累与稳健发展，现已成长为具有全球竞争力与影响力的企业。在技术平台、产品支线、安全风控体系、运营及客户服务体系等方面，均处于全球领先地位。 定位：致力为全球用户提供专业、安全、透明的数字资产金融服务。 规模：覆盖全球全球160多个城市，拥有8900万+用户，累计交易金额超过0.8万亿美元。 安全：安全运营，数字资产存储多重签名数字货币钱包。 稳定：全球顶尖技术团队，分布式并行技术，高速撮合引擎。 专业：超强金融风控团队，支持API交易。 【产品特点】 1.支持多币种的交易：LTC/BTC、ETH/BTC、BCH/BTC、ETC/BTC、BTC/USDT、LTC/USDT、ETH/ USDT、BCH/ USDT、ETC/ USDT、EUR/USD、GBP/USD、AUD/USD、XAU/USD等。 2.实时价格 3.专业K线图、实时指标 4.灵活交易方式：现价交易、市价交易、止盈止损交易 5.提币充币快速到账 6.7*24小时多语言服务 7.便捷的资产查询',
        text6: '收起',
        text7: '展开',
        text8: '其他信息',
        text9: '提供者',
        text10: '系统要求',
        text11: '因设备而异',
        text12: '访问网站',
        text13: '岁',
        text22: '更新日期',
        text23: '2021年3月30日',
        text24: '大小',
        text25: '安装次数',
        text26: '当前版本',
        text27: '内容分级',
        text28: '开发者',
    }
}