









import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        
    }
})
export default class Home extends Vue {
   private _i18n: any;
   created(){
       if(location.hash.indexOf('zh')!=-1){
            this._i18n.locale = 'zh'
            localStorage.setItem("applang", 'zh')
       }else{
            this._i18n.locale = 'en'
            localStorage.setItem("applang", 'en')
       }
   }

}
